import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
//  Users
import UserTable from "../component/users/users";
import RequestedUsers from "../component/users/requestedUsers";
import AcceptedUsers from "../component/users/acceptedUsers";
import UserDetail from "../component/users/userDetails";
// Dashboard
// import Dashboard from "../component/dashboard/dashboard";
// Admin
import AdminTable from "../component/admin/adminTable";
// contact
import Contact from "../component/contact/contact";
// login
import Login from "../component/login/login";
// cookie
import cookie from "react-cookies";
// Layout
import Layout from "../shared/layout";

const AllRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  // useEffect for loggedIn user to check
  useEffect(() => {
    let token = cookie.load("token");
    if (token !== undefined) {
      // navigate(-1);
      setLoggedIn(true);
    } else {
      navigate("/login");
      setLoggedIn(false);
    }
    // eslint-disable-next-line
  }, [cookie.load("token")]);

  return (
    <React.Fragment>
      {/* login page */}
      {!loggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            {/* Admin */}
            <Route path="/admins" element={<AdminTable />} />
            {/* Users */}
            <Route path="/users" element={<UserTable />} />
            <Route path="/users-requests" element={<RequestedUsers />} />
            <Route path="/users-accepted" element={<AcceptedUsers />} />
            <Route path="/user-detail/:id" element={<UserDetail />} />
            {/* contact */}
            <Route path="/contact" element={<Contact />} />
            {/* dashboard */}
            <Route path="/" element={<AdminTable />} />
          </Routes>
        </Layout>
      )}
    </React.Fragment>
  );
};
export default AllRoutes;
