import { useState, useEffect } from "react";
import { Space, Table } from "antd";
import cookie from "react-cookies";
import axios from "axios";

const Admintable = () => {
  const [adminData, setAdmindata] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  // method for API call
  const getAdminHandler = (page) => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/profiles/admins?page=${page}&limit=10`,
        config
      )
      .then((response) => {
        setAdmindata(response?.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  //  API for admins
  useEffect(() => {
    getAdminHandler(1);
    // eslint-disable-next-line
  }, []);

  // table columns
  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "",
      key: "full_name",
      render: (data) => <Space size="middle">{data?.full_name}</Space>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "",
      render: (data) => <Space size="middle">{data?.role}</Space>,
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      render: (data) => <Space size="middle">{data}</Space>,
    },
  ];
  return (
    <div>
      <div className="header">
        <h3>All Admins</h3>
      </div>
      {/* table */}
      <Table
        columns={columns}
        dataSource={adminData?.data?.data}
        loading={loader}
        pagination={true}
        scroll={{ x: 1023 }}
      />
    </div>
  );
};
export default Admintable;
