import React, { useState } from "react";
import Logo from "../../assets/logos/logo1.png";
import LogoResponsive from "../../assets/logos/logo-responsive.png";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ContainerOutlined,
  UserOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  DollarOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  ReconciliationOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import MenuButton from "../dropdown/Menu";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const Layout1 = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  // token for color
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // function to get Tabs Props
  const getItem = (label, key, icon, children, theme) => {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  };

  // handler to show active part
  const activeTabHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/":
        return "1";
      case "/admins":
        return "2";
      case "/users":
        return "3";
      case `/user-detail/${location?.pathname?.split("/")[2]}`:
        return "3";
      case "/users-accepted":
        return "4a";
      case "/users-requests":
        return "4";
      case "/contact":
        return "5";
      case "/Logout":
        return "6";
      default:
        return "1";
    }
  };

  // handler to show active sub menu options
  const activeSubOptionHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/users":
        return "sub4";
      case `/user-detail/${location?.pathname?.split("/")[2]}`:
        return "sub4";
      case "/users-accepted":
        return "sub4";
      case "/subscribed-users":
        return "sub1";
      default:
        return "";
    }
  };

  // sidenav items
  const items = [
    // getItem(<Link to="/">Dashboard</Link>, "1", <AppstoreOutlined />),
    getItem(<Link to="/admins">Admin</Link>, "2", <UserOutlined />),
    getItem(<Link>Sellers</Link>, "sub4", <TeamOutlined />, [
      getItem(<Link to="/users?page=1">Sellers</Link>, "3"),
      getItem(<Link to="/users-accepted">Accepted Cars</Link>, "4a"),
      getItem(<Link to="/users-requests">Requested Cars</Link>, "4"),
    ]),
    getItem(<Link to="/contact">Contact Us</Link>, "5", <FileTextOutlined />),
  ];
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <div className="sidebar_logo">
          {!collapsed ? (
            <React.Fragment>
              {/* <img src={Logo} alt="" /> */}
              <h2 style={{ color: "#fff" }}>WheelsForRent</h2>
            </React.Fragment>
          ) : (
            // <img src={LogoResponsive} alt="" />
            <h2 style={{ color: "#fff", fontSize: "15px" }}>Wheels</h2>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeTabHandler(location?.pathname)]}
          defaultOpenKeys={[activeSubOptionHandler(location?.pathname)]}
          items={items}
          selectedKeys={[activeTabHandler(location?.pathname)]}
          // openKeys={[activeSubOptionHandler(location?.pathname)]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "20px",
              width: 64,
              height: 64,
            }}
          />
          <MenuButton />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflowY: "scroll",
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Layout1;
