import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Space, Avatar } from "antd";
import Cookie from "react-cookies";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MenuButton = () => {
  const navigate = useNavigate();

  const logout = () => {
    Cookie.remove("token");
    navigate("/login");
  };

  // item for admin dropdown option
  const items = [
    {
      label: (
        <Link
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Link>
      ),
      key: "0",
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <Link onClick={(e) => e.preventDefault()} className="menu_button">
          <Space>
            Admin
            <Avatar
              style={{
                backgroundColor: "#626ed4",
              }}
              icon={<UserOutlined />}
            />
          </Space>
        </Link>
      </Dropdown>
    </>
  );
};
export default MenuButton;
