import { Button, Form, Checkbox, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Logo from "../../assets/logos/logo1.png";
import axios from "axios";
import cookie from "react-cookies";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  // const [setCookie] = useCookies(["token"]);

  const onChange = (newName) => {
    cookie.save("token", newName, { path: "/" });
  };

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  
  // onFinish
  const onFinish = (values) => {
    setLoader(true);
    console.log(values);
    const payload = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(`${process.env.REACT_APP_FRONTED_URL}/auth/login`, payload)
      .then((response) => {
        onChange(
          response?.data?.data?.token &&
            JSON.stringify(response?.data?.data?.token)
        );
        setLoader(false);
        openNotification("Success", "Your are loggedIn Successfully");
        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
        openNotification("Error", "Incorrect email and password");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="logo-container">
            <h1 style={{ color: "#000", fontSize: "30px", margin: "0px" }}>
              WheelsForRent
            </h1>
          </div>
          <Form.Item
            name="email"
            label="Email"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input placeholder="Email" style={{ fontSize: "16px" }} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              style={{ fontSize: "16px" }}
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="login-form-button">
              {loader ? <Spin /> : "LOGIN"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
